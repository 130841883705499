* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;

}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
   }
}

body {
  background: var(--color-bg);
  animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}
  


a {
  color: unset;
  text-decoration: none;
}
.gradient__bg {

    /* ff 3.6+ */
    background:-moz-radial-gradient(circle at 11% 23%, #002853 0%, #040C18 100%, #EE82EE 100%);

    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 11% 23%, #002853 0%, #040C18 100%, #EE82EE 100%);

    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 11% 23%, #002853 0%, #040C18 100%, #EE82EE 100%);

    /* ie 10+ */
    background:-ms-radial-gradient(circle at 11% 23%, #002853 0%, #040C18 100%, #EE82EE 100%);

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 11% 23%, #002853 0%, #040C18 100%, #EE82EE 100%);
  
}

.gradient__text {
  background: var(--gradient-text) ;
  background-clip: text ;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}

.section__padding {
  padding: 4rem 6rem;
}

.section__margin {
  padding: 4rem 6rem;
}


/* Animista */

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}


 @-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@media screen and (max-width: 700px) {
  .section__padding {
    padding: 4rem;
  }

  .section__margin {
    margin: 4rem;
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 4rem 2rem;
  }

  .section__margin {
    margin: 4rem 2rem;
  }
}



.app__grid-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: var(--color-bg);
}

/* Animista Trackin in contract back */

.tracking-in-contract-bck {
	-webkit-animation: tracking-in-contract-bck 1.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
	        animation: tracking-in-contract-bck 1.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

@-webkit-keyframes tracking-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px);
            transform: translateZ(400px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes tracking-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px);
            transform: translateZ(400px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}
