.gpt3__navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  padding: 2rem 6rem;
}

.gpt3__navbar-menu_container-links p a {
  color: #FFF;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;
  margin: 0 1rem;
  cursor: pointer;
}

.gpt3__navbar-links {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.gpt3__navbar-links-logo { 
  display: flex;
  justify-content: flex-start;
  align-items: center; 
  
}

.gpt3__navbar-links-logo img {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 25%;
  height: 25%;
  cursor: pointer;
}

.gpt3__navbar-links-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

}


.gpt3__navbar-links-container p, 
.gpt3__navbar-menu_container p {
  justify-content: flex-end;
  color: #FFF;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;
  margin: 0 1rem;
  cursor: pointer;
}

.gpt3__navbar-sign button,
.gpt3__navbar-menu_container button  {
  padding: 1rem 1.5rem;
  color: #FFF;
  background: #FF4820;
  font-family: var(--font-family);
  font-weight: 500;
  left: 1153px;
  top: 55px;
  font-size: 18px;
  line-height: 25px;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
}

.gpt3__navbar-menu {
  margin-left: 1rem;
  display: none;
  position: relative;


}

.gpt3__navbar-menu svg {
  cursor: pointer;

}

.gpt3__navbar-menu_container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;

  text-align: end;
  background: var(--color-footer);
  padding: 2rem;
  position: absolute;
  top: 40px;
  right: 0;
  margin-top: 1rem;
  min-width: 210px;
  border-radius: 5px;
  box-shadow: 0 0 5 rgb(0, 0, 0, 0.2);

}

.gpt3__navbar-menu_container p {
  margin: 1rem 0;
}
.gpt3__navbar-menu_container-links-sign {
  display: none;
}

@media screen and ( max-width: 1050px ) {
.gpt3__navbar-links-container {
    display: none;
  }
  .gpt3__navbar-menu {
    display: flex;
  }
}

@media screen and ( max-width: 700px ) {
  .gpt3__navbar {
    padding: 2rem 4rem;
  }
  
}

@media screen and (max-width: 550px ) {
  .gpt3__navbar {
    padding: 2rem;
  }
  .gpt3__navbar-sign {
    display: none;

  }
  .gpt3__navbar-links-container {
    top: 20px;

  }
  .gpt3__navbar-links-container-links-sign {
    display: block;
  }
}
.slide-in-top-0 {animation:slide-in-top 1.25s ease-out both}
.slide-in-top-1 {animation:slide-in-top 1.35s ease-out both}
.slide-in-top-2 {animation:slide-in-top 1.45s ease-out both}
.slide-in-top-3 {animation:slide-in-top 1.55s ease-out both}
.slide-in-top-4 {animation:slide-in-top 1.65s ease-out both}

@keyframes slide-in-top { 
  0%{transform:translateY(-1000px);
    opacity:0
  } 100% {transform:translateY(0);
      opacity:1}
    }

