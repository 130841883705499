.gpt3__footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--color-footer);

  color: #FFF;
}

.gpt3__footer-heading {
  width: 100%;
  text-align: center;
  margin-bottom: 3rem;

}

.gpt3__footer-heading h1{
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 62px;
  line-height: 75px;
  text-align: center;
  letter-spacing: -0.04;
}

.gpt3__footer-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border: 1px solid #FFF;

  text-align: center;
  cursor: pointer;
  margin-bottom: 10rem;
}

.gpt3__footer-btn p {
  box-sizing: border-box;
  font-family: var(--font-family);
  font-size: 18px;
  line-height: 21px;
  color: #fFF;
  word-spacing: 2px;
}

.gpt3__footer-links {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;

  width: 100%;
  text-align: center;

}

.gpt3__footer-links div {
  width: 250px;
  margin: 1rem;
}

.gpt3__footer-link_logo {
  display: flex;
  flex-direction: column;
 }

 .gpt3__footer-link_logo img {
  width: 75px;
  height: 75px;
  margin-bottom: 1rem;
 }